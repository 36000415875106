import '../../App.css'

import Header from '../Header/Header.js'
import banner from '../Images/banner01.png'
import Planos from '../Planos/Planos.js'
import Footer from '../Footer/Footer.js'
import Questions from '../Questions/Questions.js'

function Home() {
  return (
    <div className="App">
      <Header />
      <img
        style={{
          background: 'linear-gradient(to right, #0E0326, #2D1A57)'
        }}
        src={banner}
        className="App-banner"
        alt="banner"
      />

      <Planos />
      <Questions />
      <Footer />
    </div>
  )
}

export default Home
