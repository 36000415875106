import React from 'react'
import Footer from '../Components/Footer/Footer.js'

export default function Termos() {
  const canais = [
    { nome: 'Canal 1', numero: 101 },
    { nome: 'Canal 2', numero: 102 },
    { nome: 'Canal 3', numero: 103 }
    // ... adicione mais canais conforme necessário
  ]

  return (
    <div className="App">
      <h2>Canais Disponíveis</h2>
      <ul>
        {canais.map((canal, index) => (
          <li key={index}>
            {canal.nome} - Número: {canal.numero}
          </li>
        ))}
      </ul>
      {/* Aqui você pode adicionar o QR code ou outras informações relacionadas aos canais */}

      <Footer />
    </div>
  )
}
