import React, { useState } from 'react'
import './questions.css'

import arrow from '../Images/arrow.png'

export default function Questions() {
  // Use o estado para controlar a visibilidade das informações
  const [showInfo, setShowInfo] = useState(false)
  const [showInfo1, setShowInfo1] = useState(false)
  const [showInfo2, setShowInfo2] = useState(false)
  const [showInfo3, setShowInfo3] = useState(false)
  const [showInfo4, setShowInfo4] = useState(false)
  const [showInfo5, setShowInfo5] = useState(false)
  const [showInfo6, setShowInfo6] = useState(false)
  const [showInfo7, setShowInfo7] = useState(false)

  const toggleInfo = index => {
    switch (index) {
      case 0:
        setShowInfo(!showInfo)
        break
      case 1:
        setShowInfo1(!showInfo1)
        break
      case 2:
        setShowInfo2(!showInfo2)
        break
      case 3:
        setShowInfo3(!showInfo3)
        break
      case 4:
        setShowInfo4(!showInfo4)
        break
      case 5:
        setShowInfo5(!showInfo5)
        break
      case 6:
        setShowInfo6(!showInfo6)
        break
      case 7:
        setShowInfo7(!showInfo7)
        break
      default:
        // Caso o índice não corresponda a nenhum caso, não faz nada.
        break
    }
  }

  return (
    <div
      style={{
        background: 'linear-gradient(to left, #0E0326, #2D1A57)',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ marginBottom: '4rem' }}>
        <text class="text-title1">Perguntas Frequentes</text>
      </div>
      <div class="div-questions">
        <ul class="ul-questions">
          <li class="text-question">
            O que é a Womp Play?
            <button onClick={() => toggleInfo(0)} className={`toggle-button ${showInfo ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo ? 'show' : ''}`} aria-hidden={!showInfo}>
            <p class="test-response">Womp Play é um aplicativo para assistir TV ao vivo, 100% online, em qualquer lugar do Brasil.</p>
            <p class="test-response">
              A Womp Play está inovando a experiência de assistir TV ao vivo com uma navegação simples, focada no usuário final, usando tecnologia própria com funcionabilidades exclusivas e canais
              oferecidos de forma completamente legalizada.
            </p>
            <p class="test-response">O app está disponível para Smart TV Samsung, ROKU, Android TV, Smartphones Android, Apple TV, iPhone e iPad.</p>
          </div>
          <p class="text-plan1"></p>
          <li class="text-question">
            Como posso assistir TV com a Womp Play?
            <button onClick={() => toggleInfo(1)} className={`toggle-button ${showInfo1 ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo1 ? 'show' : ''}`} aria-hidden={!showInfo1}>
            <p class="test-response">Para começar, você deve fazer download do nosso app na loja de aplicativos do seu smartphone;</p>
            <p class="test-response">em seguida, ao iniciar o aplicativo, insira o login e senha que você recebeu via email da Womp no ato da contratação do seu plano Womp Play;</p>
            <p class="test-response">Pronto, a partir daqui você estará dentro do universo Womp e poderá assistir tudo o que quiser, onde quiser.</p>
          </div>
          <p class="text-plan1"></p>
          <li class="text-question">
            Em quais dispositivos posso acessar a Womp Play?
            <button onClick={() => toggleInfo(2)} className={`toggle-button ${showInfo2 ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo2 ? 'show' : ''}`} aria-hidden={!showInfo2}>
            <p class="test-response">Você pode baixar a Womp Play na sua Smart TV Samsung, Smart TV LG, Android TV, Apple TV, Roku, Smartphone e Tablet Android, iPhone, iPad e Google Chromecast.</p>
          </div>
          <p class="text-plan1"></p>
          <li class="text-question">
            É necessário ter internet para assistir a Womp Play?
            <button onClick={() => toggleInfo(3)} className={`toggle-button ${showInfo3 ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo3 ? 'show' : ''}`} aria-hidden={!showInfo3}>
            <p class="test-response">
              Sim, para usar a Womp Play você precisa ter uma conexão com a internet de no mínimo 25 Mbps. Para uma melhor experiência, recomendamos a rede Wi-Fi, porém, também é possível acessar
              utilizando dados móveis. Nossa tecnologia entrega uma boa qualidade de imagem consumindo menos dados.
            </p>
          </div>
          <p class="text-plan1"></p>
          <li class="text-question">
            Em quantas telas posso ver ao mesmo tempo?
            <button onClick={() => toggleInfo(4)} className={`toggle-button ${showInfo4 ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo4 ? 'show' : ''}`} aria-hidden={!showInfo4}>
            <p class="test-response">Depende do pacote de canais que você assinou.</p>
            <p class="test-response">Star Womp Play - Pode registrar quantos dispositivos quiser - Pode assistir em apenas 2 dispositivo por vez</p>
            <p class="test-response">Full Womp Play - Pode registrar quantos dispositivos quiser - Pode assistir em apenas até 5 dispositivos ao mesmo tempo, com o limite de 2 localizações</p>
          </div>
          <p class="text-plan1"></p>
          <li class="text-question">
            Preciso de ajuda, como posso fazer para falar com a Womp Play?
            <button onClick={() => toggleInfo(5)} className={`toggle-button ${showInfo5 ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo5 ? 'show' : ''}`} aria-hidden={!showInfo5}>
            <p class="test-response">Você tem duas opções, pode mandar uma mensagem por WhatsApp ou realizar uma chamada de voz para o número (92) 4020-9933.</p>
            <p class="test-response">Seja como for, não hesite em falar conosco, estamos aqui pra te ajudar.</p>
          </div>
          <p class="text-plan1"></p>
          <li class="text-question">
            Quais são os canais disponíveis na Womp Play?
            <button onClick={() => toggleInfo(6)} className={`toggle-button ${showInfo6 ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo6 ? 'show' : ''}`} aria-hidden={!showInfo6}>
            <p class="test-response">O plano Star Womp Play conta com mais de 120 canais</p>
            <p class="test-response">O Plano Full Womp Play conta com mais de 185 canais</p>
            <p class="test-response">
              <a
                href="/canais"
                style={{
                  textDecoration: 'none',
                  color: '#FDEB1A',
                  fontFamily: 'Barlow',
                  fontWeight: 600
                }}
              >
                Clique aqui para ver a lista completa e detalhada de todos os canais.
              </a>
            </p>
          </div>
          <p class="text-plan1"></p>
          <li class="text-question">
            Womp Play é um aplicativo legalizado?
            <button onClick={() => toggleInfo(7)} className={`toggle-button ${showInfo7 ? 'active' : 'deactive'}`}>
              <img class="arrow-button" src={arrow} alt="" />
            </button>
          </li>
          <div className={`div-response ${showInfo7 ? 'show' : ''}`} aria-hidden={!showInfo7}>
            <p class="test-response">
              Forte e claro: sim! É 100% legal. E os IPTVs baratinhos? Os IPTVs que te oferecem muitos canais por um preço baixíssimo são ilegais e estão roubando o sinal dos canais. Na Womp Play
              temos contrato com cada um dos canais que está no app e pagamos por todo o conteúdo que você assiste.
            </p>
          </div>

          <p class="text-plan1"></p>
        </ul>
      </div>
    </div>
  )
}
