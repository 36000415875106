import React, { useState } from 'react'
import './header.css'
import logo from '../Images/wompplay.png'
import hamburguer from '../Images/R.png'
//import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom' // Importe useLocation do react-router-dom

import Link from '@mui/material/Link'

import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Menu from '@mui/material/Menu'

// importing material UI components
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { createTheme, ThemeProvider } from '@mui/material/styles'

// MATERIAL-UI
const { palette } = createTheme()
const { augmentColor } = palette
const createColor = mainColor => augmentColor({ color: { main: mainColor } })
const theme = createTheme({
  palette: {
    anger: createColor('#F40B27'),
    apple: createColor('#5DBA40'),
    steelBlue: createColor('#5C76B7'),
    violet: createColor('#BC00A3')
  }
})

const styles = {
  appBar: {
    background: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed'
  },
  link: {
    flex: 1
  },
  button: {
    fontSize: 12,
    fontFamily: 'Barlow',
    textTransform: 'none',
    marginLeft: 20,
    textDecoration: 'none',
    color: '#FFFFFF',
    fontWeight: '800'
  },
  outlinedButton: {
    fontSize: 15,
    fontFamily: 'Barlow',
    textTransform: 'none',
    color: '#0E0326',
    borderRadius: 10,
    paddingTop: '0.1em',
    paddingBottom: '0.1em',
    marginLeft: '0.5rem',
    fontWeight: '800',
    backgroundColor: '#FDEB1A',
    borderColor: '#000'
  }
}

export default function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // Use useLocation para obter a rota atual
  const location = useLocation()

  const pages = [
    { name: 'INÍCIO', url: '/' },
    { name: 'CANAIS', url: '/canais' },
    { name: 'PLANOS', url: '/plans' },
    { name: 'DISPOSITIVOS', url: '/dispositivos' },
    { name: 'CONTATO', url: '/contato' },
    { name: 'AJUDA', url: '/ajuda' },
    { name: 'ENTRAR', url: 'http://play.wompplay.com' }
  ]

  const pages1 = [
    { name: 'INÍCIO', url: '/' },
    { name: 'CANAIS', url: '/canais' },
    { name: 'PLANOS', url: '/plans' },
    { name: 'DISPOSITIVOS', url: '/dispositivos' },
    { name: 'CONTATO', url: '/contato' },
    { name: 'AJUDA', url: '/ajuda' },
    { name: 'ENTRAR', url: 'http://play.wompplay.com' },
    { name: 'ASSINE AGORA', url: 'https://checkout.wompplay.com' }
  ]

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" sx={styles.appBar}>
        <Container maxWidth="x1">
          <Toolbar disableGutters>
            <IconButton size="large" aria-label="menu" onClick={handleOpenNavMenu} color="inherit" sx={{ display: { xs: 'flex', md: 'none' } }}>
              <img src={hamburguer} className="App-logo2" alt="logo" />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component={Link}
              href="/"
              sx={{
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center'
              }}
            >
              <img src={logo} className="App-logo1" alt="logo" />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end'
              }}
            >
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit" sx={{ p: 0 }}>
                <img alt="logo" className="App-logo" src={logo} />
              </IconButton>
            </Box>
            <Box
              sx={{
                flexGrow: 7,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'flex-end'
              }}
            >
              {pages.map(page => (
                <Link
                  key={page.name}
                  href={page.url} // URL correspondente para cada página
                  underline="none"
                  color="inherit"
                >
                  <Button
                    sx={{
                      my: 4,
                      color: location.pathname === page.url ? '#FDEB1A' : '#FFF',
                      fontFamily: 'Barlow',
                      fontSize: location.pathname === page.url ? '0.8em' : '0.8em',
                      fontWeight: location.pathname === page.url ? 800 : 800,
                      marginLeft: location.pathname === page.url ? '0.2rem' : '0.2rem'
                    }}
                  >
                    {page.name}
                  </Button>
                </Link>
              ))}
              <Button onClick={handleCloseNavMenu} sx={{ my: 3, color: 'white' }} variant="outlined" style={styles.outlinedButton}>
                <a href="https://checkout.wompplay.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  ASSINE AGORA
                </a>
              </Button>
            </Box>
          </Toolbar>
        </Container>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
            className: 'menu-typography'
          }}
        >
          {pages1.map(page => (
            <Link
              key={page.name}
              href={page.url} // URL correspondente para cada página
              underline="none"
              color="inherit"
              onClick={handleCloseNavMenu}
            >
              <MenuItem key={page.name} onClick={handleCloseNavMenu} className="menu-item">
                <Typography className="text-typography" textAlign="center">
                  {page.name}
                </Typography>
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </AppBar>
    </ThemeProvider>
  )
}
