import React from 'react'
import Header from '../Components/Header/Header.js'
import Questions from '../Components/Questions/Questions.js'
import Footer from '../Components/Footer/Footer.js'

import tvsamsung from '../Components/Images/smartv_samsumg_wompplay_300x300.png'
import tvlg from '../Components/Images/smartv_lg_wompplay_300x300.png'
import tvios from '../Components/Images/ios_wompplay_300x300.png'
import tvapple from '../Components/Images/appletv_wompplay_300x300.png'
import tvandroid from '../Components/Images/androidtv_wompplay_300x300.png'
import android from '../Components/Images/android_wompplay_300x300.png'
import chromecast from '../Components/Images/chromecast_wompplay_300x300.png'
import roku from '../Components/Images/roku_wompplay_300x300.png'

export default function Dispositivos() {
  return (
    <div className="App">
      <Header />
      <div
        style={{
          background: 'linear-gradient(to left, #0E0326, #2D1A57)',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '8em',
          paddingBottom: '5em'
        }}
      >
        <div class="div-title1">
          <p class="text-sub">Veja os</p>
          <p class="text-title1">Dispositivos compatíveis</p>
        </div>
        <div>
          <div class="div-comp-img">
            <div class="div-img">
              <img class="div-img-tv" src={tvsamsung} alt="" />
              <p class="img-text-tv">Smart TV Samsung</p>
              <p class="img-sub-tv">Disponível nos modelos Samsung com sistema Tizen (2016 ou superior).</p>
            </div>
            <div class="div-img">
              <img class="div-img-tv" src={tvlg} alt="" />
              <p class="img-text-tv">Smart TV LG</p>
              <p class="img-sub-tv">Disponível nos modelos LG com sistema WebOS 3.0 (2014) ou superior.</p>
            </div>
            <div class="div-img">
              <img class="div-img-tv" src={tvandroid} alt="" />
              <p class="img-text-tv">Android TV</p>
              <p class="img-sub-tv">Disponível para Smart TVs com sistema Android TV.</p>
            </div>
            <div class="div-img">
              <img class="div-img-tv" src={tvapple} alt="" />
              <p class="img-text-tv">Apple TV</p>
              <p class="img-sub-tv">Disponível para Apple TV 4ª geração, na Appstore.</p>
            </div>
            <div class="div-img">
              <img class="div-img-tv" src={roku} alt="" />
              <p class="img-text-tv">Roku</p>
              <p class="img-sub-tv">Disponível para todos os modelos Roku.</p>
            </div>
            <div class="div-img">
              <img class="div-img-tv" src={chromecast} alt="" />
              <p class="img-text-tv">Chromecast</p>
              <p class="img-sub-tv">Disponível para transmissão via Chromecast a partir de dispositivos Android e iOS.</p>
            </div>
            <div class="div-img">
              <img class="div-img-tv" src={tvios} alt="" />
              <p class="img-text-tv">iOS</p>
              <p class="img-sub-tv">Compatível com a versão 12.0 e Iphone versão 7 ou superior.</p>
            </div>
            <div class="div-img">
              <img class="div-img-tv" src={android} alt="" />
              <p class="img-text-tv">Android</p>
              <p class="img-sub-tv">Compatível com tablets e celulares com Android versão 5.0 ou superior</p>
            </div>
          </div>
        </div>
      </div>
      <Questions />
      <Footer />
    </div>
  )
}
