import React from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './styles.css'

import Header from '../Components/Header/Header.js'
import Questions from '../Components/Questions/Questions.js'
import Footer from '../Components/Footer/Footer.js'

import Boy from '../Components/Images/boy_yellow_womplay.png'

export default function Contato() {
  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const response = await axios.post('/api/send-email', {})

      if (response.status === 200) {
        toast.success('E-mail enviado com sucesso!')
      } else {
        toast.error('Estamos trabalhando para que as mensagens possam ser enviadas.')
      }
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error)
      toast.error('Estamos trabalhando para que as mensagens possam ser enviadas.')
    }
  }

  return (
    <div className="App">
      <Header />
      <div
        style={{
          background: 'linear-gradient(to left, #0E0326, #2D1A57)',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '8em',
          paddingBottom: '5em'
        }}
      >
        <div class="div-title1">
          <p class="text-sub">Entre em contato e</p>
          <p class="text-title1">fale com a gente!</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="div-form">
            <div class="div-form1">
              <p class="text-form">Nome</p>
              <input class="text-form-input" type="text" autocomplete="off" disabled={true} />
              <p class="text-form">E-mail</p>
              <input class="text-form-input" type="text" autocomplete="off" disabled={true} />
              <p class="text-form">Telefone</p>
              <input class="text-form-input" type="text" autocomplete="off" disabled={true} />
              <p class="text-form">Assunto</p>
              <input class="text-form-input-ass" type="text" autocomplete="off" disabled={true} />
              <div>
                <button class="form-button" type="submit">
                  <p class="text-form-button">ENVIAR MENSAGEM</p>
                </button>
              </div>
            </div>
            <div className="div-boy">
              <img className="image-boy" src={Boy} alt="" />
            </div>
          </div>
        </form>
        <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_LEFT} />
      </div>
      <Questions />
      <Footer />
    </div>
  )
}
